import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="feedbackstudiologo.png"
          // className="App-logo"
          alt="logo"
          width="200"
        />
        <p>Webhook app for Feedback Studio</p>
        <a
          className="App-link"
          href="https://admin.feedbackstudio.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to Feedback Studio
        </a>
      </header>
    </div>
  )
}

export default App
